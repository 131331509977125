import React from 'react';
import tw, { styled } from 'twin.macro';
import { MdLocationOn, MdPhone, MdEmail } from 'react-icons/md';

import useCompanyInfo from '../hooks/use-company-info';

const AddressCardContainer = styled.div`
  ${tw`mt-4`}
`;

const Address = styled.ul`
  ${tw`list-none`}
`;

const AddressItem = styled.li`
  ${tw`pb-3 text-lg flex`}
`;

const IconWrapper = styled.span`
  ${tw`relative pr-4`}
  top: 4px;
`;

const LocationIconWrapper = styled.div`
  ${tw`relative pr-4`}
  top: 6px;
`;

const AddressCard = () => {
  const company = useCompanyInfo();
  const { address } = company;

  return (
    <AddressCardContainer>
      <Address>
        <AddressItem>
          <LocationIconWrapper>
            <MdLocationOn />
          </LocationIconWrapper>
          <div>
            {address.address1} <br />
            {address.city}, {address.state} {address.zipCode}
          </div>
        </AddressItem>
        <AddressItem>
          <IconWrapper>
            <MdPhone />
          </IconWrapper>
          <span>
            <a href={`tel:${company.phoneNum}`}>{company.phoneNum}</a>
          </span>
        </AddressItem>
        <AddressItem>
          <IconWrapper>
            <MdEmail />
          </IconWrapper>
          <span>
            <a href={`mailto:${company.email}`}>{company.email}</a>
          </span>
        </AddressItem>
      </Address>
    </AddressCardContainer>
  );
};

export default AddressCard;
