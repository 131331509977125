import React from 'react';
import tw, { styled } from 'twin.macro';

import useAffiliations from '../hooks/use-affiliations';
import useCompanyInfo from '../hooks/use-company-info';

const AffiliateContainer = styled.div`
  ${tw`pr-0 text-base`}
`;

const AffiliateLinks = styled.ul`
  ${tw`list-none pl-0 pt-4`};
`;

const LinkItem = styled.a`
  ${tw`block underline font-medium text-base pb-4`};
`;

const Callout = styled.span`
  ${tw`font-medium text-lg`};
`;

const AffiliateLink = props => {
  const { url, label } = props;
  return (
    <li>
      <LinkItem href={url} alt={label}>
        {label}
      </LinkItem>
    </li>
  );
};

const Affiliations = () => {
  const { name } = useCompanyInfo();
  const affiliates = useAffiliations();

  return (
    <AffiliateContainer>
      <p>
        <Callout>{name}</Callout> is a division of Frying Pan Anglers. Click the
        links below to visit our other properties.
      </p>
      <AffiliateLinks>
        {affiliates.map((a, k) => (
          <AffiliateLink url={a.affiliateUrl} label={a.affiliateName} key={k} />
        ))}
      </AffiliateLinks>
    </AffiliateContainer>
  );
};

export default Affiliations;
