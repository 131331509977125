import { useStaticQuery, graphql } from 'gatsby';

const useCompanyInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityCompanyInfo {
        name
        email
        phoneNum
        address {
          address1
          address2
          city
          state
          zipCode
        }
      }
    }
  `);

  return data.sanityCompanyInfo;
};

export default useCompanyInfo;
