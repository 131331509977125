import React from 'react';
import tw, { styled } from 'twin.macro';

import useCompanyInfo from '../hooks/use-company-info';
import AddressCard from './address-card';
import Social from './social';

const FindUsContainer = styled.div`
  ${tw`md:p-4 pl-0 text-base`};
`;

const CompanyName = styled.p`
  ${tw`mb-4 font-medium text-lg`};
`;

const FindUs = () => {
  const { name } = useCompanyInfo();

  return (
    <FindUsContainer>
      <CompanyName>{name}</CompanyName>
      <AddressCard />
      <Social />
    </FindUsContainer>
  );
};

export default FindUs;
