import React from 'react';
import tw, { styled } from 'twin.macro';

import Affiliations from './affiliations';
import FindUs from './find-us';
import ScrollTop from './scroll-top';

const FooterWrapper = styled.footer`
  ${tw`md:flex w-auto p-8 pb-8 font-light bg-primary-600 text-txt-200`};
  background: linear-gradient(to right, #163e3e, #1e8856);
`;

const FooterItem = styled.div`
  ${tw`flex-none md:flex-1 pr-8 pb-8`};
`;

const FooterTitle = styled.h2`
  ${tw`font-heading uppercase font-normal text-xl mb-6`};
`;

const Footer = () => (
  <FooterWrapper>
    <FooterItem>
      <FooterTitle>Find Us</FooterTitle>
      <FindUs />
    </FooterItem>
    <FooterItem></FooterItem>
    <FooterItem>
      <FooterTitle>Affiliations</FooterTitle>
      <Affiliations />
    </FooterItem>
    <ScrollTop />
  </FooterWrapper>
);

export default Footer;
