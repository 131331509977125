/* eslint no-unused-vars: 0 */
import React from 'react';
import tw, { styled } from 'twin.macro';

import { FaGoogle } from 'react-icons/fa';

import useSocial from '../hooks/use-social';

const SocialContainer = styled.ul`
  ${tw`list-none`}
`;

const IconWrapper = styled.span`
  ${tw`relative pr-4`}
  top: 4px;
`;

const SocialLabel = styled.span``;

const SocialLinkItem = styled.li`
  ${tw`pb-0 text-lg flex`}
`;

const SocialLink = styled.a`
  ${tw`underline`};
`;

const iconMap = new Map([
  ['Google', 'FaGoogle'],
  ['Facebook', 'FaFacebook'],
  ['Instagram', 'FaInstagram'],
  ['Yelp', 'FaYelp'],
  ['Twitter', 'FaTwitter'],
]);

const SocialAccount = props => {
  const { href, title } = props;

  return (
    <SocialLinkItem>
      <IconWrapper>
        <FaGoogle />
      </IconWrapper>
      <SocialLink href={href}>
        <SocialLabel>{title}</SocialLabel>
      </SocialLink>
    </SocialLinkItem>
  );
};

const Social = () => {
  const social = useSocial();

  return (
    <SocialContainer>
      {social.map((a, k) => (
        <SocialAccount href={a.url} title={a.title} icon={a.icon} key={k} />
      ))}
    </SocialContainer>
  );
};

export default Social;
