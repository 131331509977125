import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import '../utils/bulma-nav.css';
import 'react-image-lightbox/style.css';

import Footer from './footer';
import Navigation from './navigation';

const PageContainerWrapper = styled.div`
  ${tw`font-body font-light text-lg mx-auto max-w-screen-xl text-gray-800`}
`;
const ContentWrapper = styled.div`
  ${tw`mt-12 font-body bg-content-100`}
`;

const PageContainer = ({ children }) => (
  <PageContainerWrapper>
    <Navigation />
    <ContentWrapper>{children}</ContentWrapper>
    <Footer />
  </PageContainerWrapper>
);

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;
