import { useStaticQuery, graphql } from 'gatsby';

const useSocial = () => {
  const data = useStaticQuery(graphql`
    query {
      sanitySocialAccounts {
        accounts {
          icon
          title
          url
        }
      }
    }
  `);

  return data.sanitySocialAccounts.accounts;
};

export default useSocial;
