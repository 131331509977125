/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';

import logo from '../images/tcc-logo@2x.png';

const NavLink = props => {
  const { to, label } = props;
  return (
    <Link to={to} className="navbar-item" activeClassName="is-active">
      {label}
    </Link>
  );
};

const Navigation = () => {
  const [isScrolling, setIsScrolling] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolling = window.scrollY !== 0;
      setIsScrolling(scrolling);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    const navBurger = document.querySelector('.navbar-burger');
    const { target } = navBurger.dataset;
    const navMenu = document.getElementById(target);

    const handleClick = () => {
      navBurger.classList.toggle('is-active');
      navMenu.classList.toggle('is-active');
    };

    navBurger.addEventListener('click', handleClick, { passive: true });
    return () => navBurger.removeEventListener('click', handleClick);
  });

  return (
    <nav
      className={`navbar is-primary top-navbar is-fixed-top ${
        isScrolling ? 'scrolling' : ''
      }`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link
          to="/"
          className="navbar-item"
          title="Taylor Creek Cabins"
          style={{ width: '181px' }}
          tw=""
        >
          <img
            src={logo}
            alt="green cabin with tc in middle"
            tw="inline pr-2"
          />
          <span tw="text-center leading-none font-thin">
            taylor creek <br /> cabins
          </span>
        </Link>
        <div className="navbar-burger burger" data-target="navMenu">
          <span />
          <span />
          <span />
        </div>
      </div>
      <div id="navMenu" className="navbar-menu">
        <div className="navbar-end">
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link" href="#">
              Lodging
            </a>
            <div className="navbar-dropdown">
              <NavLink to="/lodging/cabin-rental/" label="Cabins" />
              <NavLink
                to="/lodging/studio-cabin-rental/"
                label="Studio Cabin"
              />
              <NavLink to="/lodging/house-rental/" label="House" />
              <hr className="navbar-divider"></hr>
              <NavLink to="/basalt-lodging/" label="Additional Options" />
            </div>
          </div>
          <NavLink to="/basalt-fly-fishing/" label="Fly Fishing" />
          <NavLink to="/basalt-activities/" label="About Basalt" />
          <NavLink to="/directions/" label="Directions" />
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
