import React, { useState, useEffect } from 'react';
import { Icon } from 'react-icons-kit';
import { caretUp } from 'react-icons-kit/fa/caretUp';
import { styled } from 'twin.macro';

const ScrollContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 25px;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  z-index: 50;
  opacity: ${props => (props.show ? 1 : 0)};
`;
const ScrollIconLink = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  line-height: 44px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #163e3e;
  svg {
    color: white;
    font-size: 20px;
  }
`;

const ScrollTop = () => {
  const [showClass, setShowClass] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolling = !(window.scrollY < 250);
      setShowClass(isScrolling);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <div>
      <ScrollContainer
        onClick={() => scrollToTop()}
        title="Back to top"
        show={showClass}
      >
        <ScrollIconLink>
          <Icon icon={caretUp} size={24} />
        </ScrollIconLink>
      </ScrollContainer>
    </div>
  );
};

export default ScrollTop;
